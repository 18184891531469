import { Nav } from "../components/nav";
import { Footer } from "../components/footer";
import { useNavigate } from "react-router-dom";

const Hero = { img: "/aboutus.jpg", img2: "/Game-lorisha.jpg" };

const AboutView = () => {
  const router = useNavigate();
  return (
    <section>
      <span className="fixed z-0 w-screen h-screen">
        <img
          className="w-full"
          src={Hero.img}
          alt="Dimension hero background"
        />
      </span>
      <Nav />
      <div className="relative z-10 bg-gradient-to-b from-black/[0.7] to-black/[0.4] backdrop-blur-md h-screen flex flex-col items-center">
        <div className="max-w-4xl my-auto">
          <div className="flex flex-col-reverse lg:flex-row lg:justify-between lg:space-x-12 my-auto">
            <div className="flex flex-col space-y-8">
              <h1 className="text-5xl text-white font-bold text-center lg:text-left">
                Our values
              </h1>
              <p className="text-white text-center lg:text-left">
                At Dimension11, our core values are creativity, authenticity, and entertainment for gamers in everything we do.
                In addition, we value diversity and equality at all levels of our organization to ensure every person has a voice when it comes to game design and development. Our teammates love the diverse views that contribute to the way our games are being created. As a team of creatives and developers, we encourage our staff and customers to escape from the limitations of the 3-dimensional world and launch into the limitless 11th Dimension.  
              </p>
              <div className="flex flex-col items-start">
                <div className="flex mx-auto w-max space-x-8 lg:mx-0">
                  <button
                    className="bg-yellow-400 text-black py-2 px-12 rounded-sm border-2 border-yellow-400 hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400"
                    onClick={() => {
                      router("/joinus", { replace: true });
                    }}
                  >
                    Join us
                  </button>
                </div>
              </div>
            </div>
            <div className="max-w-md">
              <img className="rounded-sm" src="/aboutus.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-yellow-400 md:py-8 lg:h-screen">
        <div className="flex flex-col items-center py-4 space-y-4 lg:space-y-8 lg:py-20">
          <div className="">
            <h2 className="font-bold text-5xl">About us</h2>
          </div>
          <div className="max-w-xl">
            <p className="text-xl px-4">
              Our journey began five years ago in the residence halls of the Obafemi Awolowo University campus with a group of passionate gamers turned game developers. In 2021, Dimension11 was formally established with a goal to accelerate 3D media and game development in Africa by adapting the latest in game-development technologies. Our  team is currently working on our first cross-platform video game "Legends of Orisha: Blood and Water" where players are introduced to a magical action-packed adventure in the untapped world of African Fantasy. Legends of Orisha is currently in-development using Unreal Engine 5 to showcase the high fidelity and latest technology from Epic Games.
            </p>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-white">
        <div className="flex flex-col items-center px-4 py-8 mx-auto lg:flex-row lg:justify-between lg:max-w-6xl lg:py-28">
          <div className="flex flex-col items-center space-y-4 mb-12">
            <div className="flex items-center justify-center space-x-12">
              <span className="w-36 md:w-44 lg:w-40 xl:w-48">
                <img className="rounded-full" src="/David.webp" alt="" />
              </span>
              <span className="w-36 md:w-44 lg:w-40 xl:w-48">
                <img className="rounded-full" src="/Taiwo.png" alt="" />
              </span>
            </div>
            <div className="flex items-center justify-center space-x-12">
              <span className="w-36 md:w-44 lg:w-40 xl:w-48">
                <img className="rounded-full" src="/Hektor.png" alt="" />
              </span>
              <span className="w-36 md:w-44 lg:w-40 xl:w-48">
                <img className="rounded-full" src="/sharon.png" alt="" />
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center lg:items-start space-y-6 my-auto">
            <div>
              <h1 className="font-bold text-4xl whitespace-pre-line">
                Diversity and Inclusion 
              </h1>
            </div>
            <div className="max-w-xl">
              <p>
                Our team values diversity and inclusion at every step of the game design and development process. Contributions from people from different backgrounds, gender identities, religious beliefs, country origins, and life experiences help to enhance the stories we tell through video games. We are committed to keeping our doors (and hearts) open to people who share our values, no matter who they are.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="parralax h-full w-full"
        style={{ backgroundImage: `url(${Hero.img2})` }}
      >
        <div className="flex w-full h-80 backdrop-blur flex-row justify-start container mx-auto text-white">
          <div className="flex flex-col items-start space-y-6 my-auto px-4">
            <div>
              <h1 className="font-bold text-5xl">
                Forming Lasting Social <br /> Connections
              </h1>
            </div>
            <div className="max-w-xl">
              <p>
                Here at Dimension11, we venerate the authenticity of excellence
                in everything we do, maintaining the importance of equality at
                the highest to lowest levels in our organisation and are fanatic
                believers in creativity, encouraging both our staff and
                customers to detach from the many limitations of the 3rd
                dimension of existence as they step into the 11th!
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="relative z-10">
        <Footer />
      </div>
    </section>
  );
};

export default AboutView;


