import HomeView from "../views/home";
import AboutView from "../views/about";
import GamesView from "../views/games";
import NewsView from "../views/news";
import JoinUsView from "../views/joinus";
import LocationView from "../views/location";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const AppRouter = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route index element={<HomeView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/games" element={<GamesView />} />
          <Route path="/news" element={<NewsView />} />
          <Route path="/joinus" element={<JoinUsView />} />
          <Route path="/contact-us" element={<LocationView />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRouter;
