import { Nav } from "../components/nav";
import { SimpleCards } from "../components/cards";
import { Footer } from "../components/footer";

import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import Reef from "../assets/Reef.svg";
import Africa from "../assets/A-comicade.svg";
import Itida from "../assets/Itida.svg";
import Tiec from "../assets/Tiec.svg";
import idat from "../assets/idat.png";
import DA from "../assets/DA.png"

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// const Cards = [ 
//   { 
//     id: 1,
//     img: "/placeholder.jpeg",
//     button: "About Us",
//   },
//   { id: 2, img: "/placeholder.jpeg", button: "Our Games" },
//   {
//     id: 3,
//     img: "/placeholder.jpeg",
//     button: "Location",
//   },
//   {
//     id: 4,
//     img: "placeholder.jpeg",
//     button: "News",
//   },
// ];

const sponsors = [
  {
    id: 1,
    img: DA,
  },
  {
    id: 2,
    img: idat,
  },
  {
    id: 3,
    img: Africa,
  },
  {
    id: 4,
    img: Itida,
  },
  {
    id: 5,
    img: Tiec,
  },
];

const HighLightData = [
  {
    title: "Dimension11 Games resurfaces with New Publishing update",
    description:
      "Dimension11 Games is an independent game developer based in Nigeria. Their latest project, Legends of Orisha: Blood and Water, is a Yoruba story-based action-adventure role-playing game, which has garnered significant interest since its announcement several years ago and culminated in the game taking home the 2022 GIAA One to Watch in December.",
    image:
      "https://i0.wp.com/gamesindustryafrica.com/wp-content/uploads/2021/11/RemiSplash.png?fit=1920%2C1080&ssl=1",
    link: "https://gamesindustryafrica.com/2023/03/13/dimension-11-games-resurfaces-with-new-publishing-update/",
  },
  {
    title: "Dimension 11 open up about Legends of Orisha",
    description:
      "Games Industry Africa had a chat with a representative of Dimension 11 studios, to discuss their upcoming game – Legends of Orisha: Blood and Water. This game is a Yoruba Story-Based Action-Adventure Role Playing Game that the team have been working on for over a year now and is set to launch in 2022.",
    image:
      "https://i0.wp.com/gamesindustryafrica.com/wp-content/uploads/2021/11/RemiSplash.png?fit=1920%2C1080&ssl=1",
    link: "https://gamesindustryafrica.com/2021/12/15/dimension-11-open-up-about-legends-of-orisha/",
  },
  {
    title: "Legends of Orisha: Blood and Water",
    description:
      "Legends of Orisha: Blood and Water est un jeu en cours de développement par Dimension 11 Games, studio indépendant nigérian dirigé par Ekpenisi-Igumbor Ewere. Le jeu est réalisé sous Unreal Engine et sa sortie est prévue pour 2022. Il se présente comme un RPG mêlant action et aventure. L’histoire est d’ailleurs centrée sur les Yorubas, l’une des plus grandes ethnies d’Afrique. Nous incarnons Remilekun dit « Remi », un ancien soldat dont le passé trouble revient le hanter. Traumatisé, il a depuis longtemps abandonné l’idée de s’attacher à qui que ce soit, par peur de se retrouver seul ou que son affection affecte ses missions. Forcé de reprendre les armes, son voyage est avant tout une quête de rédemption et de vérité. Il se retrouve bien malgré lui avec le poids du monde sur le dos, car le destin des êtres humains, comme des divinités, est désormais entre ses mains.",
    image: "https://i3.ytimg.com/vi/X-rFR9u-7y0/maxresdefault.jpg",
    link: "https://youtu.be/X-rFR9u-7y0",
  },
];

const HomeView = () => {
  const router = useNavigate();

  return (
    <section>
      <span className="hidden lg:flex fixed z-0 w-screen h-screen">
        <img src={"/thedimension.gif"} width={1920} height={1080} />
      </span>
      <span className="flex fixed z-0 w-screen h-screen lg:hidden">
        <img src={"/thedimension-m.gif"} width={1080} height={1920} />
      </span>
      <Nav />
      <div className="relative z-10 py-8 lg:py-20 w-full h-screen">
        <div className="flex flex-row justify-center items-end h-[80vh]">
          <div className="flex w-max space-x-8">
            <button
              className="bg-yellow-400 text-black py-2 px-12 rounded-sm border-2 border-yellow-400 hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400"
              onClick={() => {
                router("/joinus", { replace: true });
              }}
            >
              Join us
            </button>
            <button
              className="border-2 border-white-500 py-2 px-12 text-white rounded-sm hover:bg-white hover:text-black"
              onClick={() => {
                router("/about", { replace: true });
              }}
            >
              About us
            </button>
          </div>
        </div>
      </div>
      <div className="relative z-10 lg:pt-24 bg-white px-4">
        <div className="flex flex-col items-center space-y-8 py-20">
          <div className="">
            <h2 className="font-bold text-6xl">Welcome</h2>
          </div>
          <div className="max-w-xl">
            <p className="text-xl">
              Dimension11 Studios is an international indie game development studio located in Lagos and Ile-Ife, Nigeria and Dubai, United Arab Emirates. Our mission is to enrich the lives of players worldwide to share our Nigerian culture and heritage while leveraging the latest in game development technology. We want the world to experience a diverse narrative right from the heart of Africa developed by a local team of creative, skilled and passionate gamers.
            </p>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-white text-center lg:py-16 px-4">
        <h2 className="text-xl lg:text-3xl pt-24 text-black">
          INVESTORS
          <span className="text-xl lg:text-3xl text-[#facc15]"> AND</span>{" "}
          STRATEGIC PARTNERS
        </h2>
        <p className="text-sm lg:text-base pb-12 mt-4 ">
          We are backed by angel investors and key strategic partners in the video games industry.
        </p>
      </div>
      <div className="relative z-10 lg:py-8 bg-[#facc15] px-4">
        <div className="w-[80%] m-auto flex items-center h-32 gap-[1rem] justify-evenly ">
          <div className="flex w-[20%] h-fit">
            <img className="w-[100%] " src={DA} alt="" />
          </div>
          <div className="flex w-[12%] h-fit">
            <img className="w-[100%] " src={idat} alt="" />
          </div>
          <div className="flex w-[18%] h-full">
            <img className="w-full h-auto" src={Africa} alt="" />
          </div>
          <div className="flex w-[18%] h-full">
            <img className="w-full h-auto" src={Itida} alt="" />
          </div>
          <div className="flex w-[18%] h-full">
            <img className="w-full h-auto" src={Tiec} alt="" />
          </div>
        </div>
      </div>
      <div className="relative z-10 p-8 md:p-16 lg:p-28 bg-white flex justify-center">
        <div className="container flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:justify-center lg:space-x-8">
          <SimpleCards
            img={"/aboutus-rs.jpg"}
            btn={"About us"}
            url={"/about"}
          />
          <SimpleCards img={"/lorishaLogoNew.jpg"} btn={"Our Games"} url={"/games"} />
          <SimpleCards
            img={"/location.jpg"}
            btn={"Contact Us"}
            url={"/contact-us"}
          />
        </div>
      </div>

      <div className="relative z-10">
        <Footer />
      </div>
    </section>
  );
};

export default HomeView;
