import { BsLinkedin, BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaRedditAlien } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer className="bg-slate-100 p-12">
      <div className="divide-y-20">
        <div className="flex flex-col items-start space-y-6 lg:flex-row lg:justify-between">
          <div>
            <img src="/logo-black.png" width={250} height={250} alt="" />
          </div>
          <div className="flex flex-row justify-center items-center space-x-8">
            <a
              href="https://www.instagram.com/dimension11studios/"
              target="new"
            >
              <AiFillInstagram className="text-4xl" />
            </a>
            <a
              href="https://www.reddit.com/r/legendsoforishagame/"
              target="new"
            >
              <FaRedditAlien className="text-4xl" />
            </a>
            <a
              href="https://www.linkedin.com/company/dimension-11"
              target="new"
            >
              <BsLinkedin className="text-3xl" />
            </a>
            <a href="https://twitter.com/Dimension11S" target="new">
              <BsTwitter className="text-3xl" />
            </a>
          </div>
        </div>
        <hr className="my-4 lg:my-8" />
        <div className="flex flex-row justify-end space-x-6">
          <p
            className="font-light text-sm text-slate-500"
          >
            <small>&copy; Dimensions11 2022</small>
          </p>
        </div>
      </div>
    </footer>
  );
};
