import AppRouter from "./router/router";
import "./App.css";
import { useEffect } from "react";
function App() {
  useEffect(() => console.clear()); // clears the console output)

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
