import { Nav } from "../components/nav";
import { SimpleCards } from "../components/cards";
import { Footer } from "../components/footer";
import { useNavigate } from "react-router-dom";

const Hero = { img: "/xbox.jpeg" };

const GamesView = () => {
  const router = useNavigate();
  return (
    <section>
      <span className="fixed z-0 w-screen h-screen">
        <img
          className="w-full"
          src={Hero.img}
          alt="Dimension hero background"
        />
      </span>
      <Nav />
      <div className="relative z-10 bg-gradient-to-b from-black/[0.7] to-black/[0.2] backdrop-blur py-24 px-4 lg:px-0 flex flex-col items-center">
        <div className="flex flex-col items-start my-auto">
          <div className="max-w-xl">
            <div className="flex flex-col items-center space-y-12 my-auto">
              <h1 className="text-5xl text-white text-center font-bold">
                Our Games
              </h1>
              <p className="text-white">
                Our team is currently working on our first cross-platform video game "Legends of Orisha: Blood and Water" where players are introduced to a magical action-packed adventure in the untapped world of African Fantasy. Legends of Orisha is currently in-development using Unreal Engine 5 to showcase the high fidelity and latest technology from Epic Games.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-white rounded w-full mx-auto">
        <div className="flex flex-col items-center space-y-8 p-8">
          <div className="container flex flex-col items-center space-y-8 lg:flex-row lg:justify-center lg:space-x-8 lg:space-y-0">
            <a
              className="flex-1"
              href="https://legendsoforisha.game/"
              target="new"
            >
              <SimpleCards
                img={"/lorishaLogoNew.jpg"}
                btn={"Legend of orisha"}
              />
            </a>
            <a
              className="flex-1"
              href="https://daveeola.itch.io/covidrun"
              target="new"
            >
              <SimpleCards img={"/Game-covidrun.jpg"} btn={"Covid Run"} />
            </a>
          </div>
          {/* <div className="container flex flex-col space-y-8 lg:flex-row lg:justify-center lg:space-x-8 lg:space-y-0">
            <SimpleCards img={"/placeholder.jpeg"} cap={"Forza horizon"} />
            <SimpleCards img={"/placeholder.jpeg"} cap={"GTA V"} />
            <SimpleCards img={"/placeholder.jpeg"} cap={"Watch Dogs"} />
          </div> */}
        </div>
      </div>
      <div className="relative z-10 pb-28 bg-white flex flex-col items-center">
        <div className="my-8 text-center">
          <h4 className="text-4xl font-bold text-black">
            Want to join our team? <br />
            Check out open roles
          </h4>{" "}
        </div>
        <div className="flex w-max space-x-8">
          <button
            className="bg-yellow-400 text-black py-2 px-12 rounded-sm border-2 border-yellow-400 hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400"
            onClick={() => {
              router("/joinus", { replace: true });
            }}
          >
            Careers
          </button>
          <button
            className="border-2 border-black py-2 px-12 text-black rounded-sm hover:bg-white hover:text-black"
            onClick={() => {
              router("/about", { replace: true });
            }}
          >
            About us
          </button>
        </div>
      </div>
      <div className="relative z-10">
        <Footer />
      </div>
    </section>
  );
};

export default GamesView;