import { Nav } from "../components/nav";
import { Link } from "react-router-dom";
import { Footer } from "../components/footer";
import { useNavigate } from "react-router-dom";

const Hero = { img: "/location.jpg", img2: "/location.jpg" };

const LocationView = () => {
  const router = useNavigate();
  return (
    <section>
      <span className="fixed z-0 w-screen h-screen">
        <img
          className="w-full"
          src={Hero.img}
          alt="Dimension hero background"
        />
      </span>
      <Nav />
      <div className="relative z-10 h-screen py-24 flex flex-col items-start mx-12">
        <div className="flex flex-col items-center my-auto">
          <div className="max-w-lg lg:mx-12">
            <div className="flex flex-col items-start space-y-6 my-auto">
              <h1 className="text-5xl text-white text-center font-bold">
                Contact Us
              </h1>
              <p className="text-white">
                The Dimension11 team is united virtually between Nigeria and the United Arab Emirates. Our Nigeria team members are located in Lagos, the center of excellence that hosts our administrative offices, and  Ile-Ife, the cradle of Yoruba civilization and is home to our creative studio. The team in Dubai, United Arab Emirates is our gateway to international business development and partnerships.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-white p-12">
        <div className="flex flex-col space-y-8 lg:flex-row lg:justify-between lg:space-x-12 text-black">
          <div className="flex flex-col items-start space-y-6 my-auto">
            <div>
              <h1 className="font-bold text-3xl">
                Meet the Team
              </h1>
            </div>
            <div className="max-w-xl">
              <p className="text-xl">
                Interested in meeting the team in-person or visiting Ile-Ife? Send us an {" "}
                <a
                  className="text-yellow-700 hover:text-yellow-500"
                  href="mailto:contact@dimension11studios.com?subject=Requesting Meeting/Tour With the Team"
                >
                  email
                </a>

                { " "}and we’ll be in touch.
              </p>
              {/* <ul className="flex flex-col items-start space-y-4">
                <Link to="">Vancouver Neighborhoods</Link>
                <Link to="">A foodies Guide to Vancouver</Link>
                <Link to="">Explore Arts & Culture</Link>
                <Link to="">Outdoor & Adventure Activities</Link>
                <Link to="">
                  Traveling Responsibly, Respecting Indigenous Peoples
                </Link>
              </ul> */}
            </div>
          </div>
          <div className="w-full lg:w-6/12">
            <img className="rounded" src="/location.jpg" alt="" />
          </div>
        </div>
      </div>

      {/* <div
        className="parralax"
        style={{ backgroundImage: `url(${Hero.img2})` }}
      >
        <div className="bg-black/[0.2] backdrop-blur-md py-12 lg:p-40 flex flex-row justify-start text-white">
          <div className="flex flex-col items-start space-y-4 my-auto px-4">
            <div>
              <h1 className="font-bold text-4xl">
                Acknowledging Traditional <br /> Territory
              </h1>
            </div>
            <div className="max-w-xl">
              <p>
                We acknowledge that our studio operates on the traditional,
                ancestral and unceded territories of the Coast Salish people of
                the Sḵwx̱wú7mesh (Squamish), Stó:lō and Səl̓ílwətaʔ/Selilwitulh
                (Tsleil-Waututh) and xʷməθkʷəy̓əm (Musqueam) Nations. We are
                thankful we live and thrive in this beautiful land that these
                Nations nurtured.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="relative z-10 py-16 bg-white flex flex-col items-center">
        <div className="my-8 text-center">
          <h4 className="text-3xl font-bold text-black">
            Want to join our team? <br />
            Check out open roles
          </h4>{" "}
        </div>
        <div className="flex w-max space-x-8">
          <button
            className="bg-yellow-400 text-black py-2 px-12 rounded-sm border-2 border-yellow-400 hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400"
            onClick={() => {
              router("/joinus", { replace: true });
            }}
          >
            Careers
          </button>
          <button
            className="border-2 border-black py-2 px-12 text-black rounded-sm hover:bg-white hover:text-black"
            onClick={() => {
              router("/about", { replace: true });
            }}
          >
            About us
          </button>
        </div>
      </div>
      <div className="relative z-10">
        <Footer />
      </div>
    </section>
  );
};

export default LocationView;
