import "./index.css";
import { Link } from "react-router-dom";
import { CgMenuMotion } from "react-icons/cg";
import { VscClose } from "react-icons/vsc";
import { useState } from "react";

const Popup = ({ toggle }) => {
  return (
    <div className="bg-black/[0.9] backdrop-blur-sm popup">
      <button className="p-4 rounded" onClick={toggle}>
        <VscClose className="text-white text-2xl" />
      </button>
      <ul className="flex flex-col items-center w-full h-full my-auto space-y-12 text-white">
        <li>
          <Link to={"/"}>
            <img
              className="my-auto"
              src="/logo-yellow.png"
              width={200}
              height={200}
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link className="hover:text-yellow-400" to="/about">
            About Us
          </Link>
        </li>
        <li>
          <Link className="hover:text-yellow-400" to="/games">
            Our Games
          </Link>
        </li>
        <li>
          <Link className="hover:text-yellow-400" to="/news">
            Latest News
          </Link>
        </li>
        <li>
          <Link className="hover:text-yellow-400" to="/contact-us">
            Contact Us
          </Link>
        </li>
        <span className="flex flex-col items-center space-y-6">
          <li>
            <Link
              to="/joinus"
              className="bg-yellow-400 text-black py-2 px-8 rounded-sm border-2 border-yellow-400 hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400"
            >
              Join us
            </Link>
          </li>
          <button
            className="bg-transparent text-white py-2 px-10 rounded-sm border-2 border-white hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400"
            onClick={toggle}
          >
            Close
          </button>
        </span>
      </ul>
    </div>
  );
};

const Smallscreen = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  return (
    <div className="flex flex-row justify-between p-6 w-full bg-transparent">
      <Link to={"/"}>
        <img
          className="my-auto"
          src="/logo-white.png"
          width={160}
          height={160}
          alt=""
        />
      </Link>
      <button className="space-x-12 text-white" onClick={toggle}>
        <CgMenuMotion className="text-xl" />
      </button>
      {show ? <Popup toggle={toggle} /> : false}
    </div>
  );
};

export const Nav = () => {
  return (
    <div className="bg-black/[.2] backdrop-blur lg:py-3 lg:px-12 z-40 fixed w-full">
      <ul className="hidden lg:flex lg:justify-between">
        <Link to={"/"}>
          <img
            className="my-auto"
            src="/logo-white.png"
            width={160}
            height={160}
            alt=""
          />
        </Link>
        <span className="flex justify-center space-x-12 text-white">
          <li>
            <Link className="my-auto hover:text-yellow-400" to="/about">
              About Us
            </Link>
          </li>
          <li>
            <Link className="my-auto hover:text-yellow-400" to="/games">
              Our Games
            </Link>
          </li>
          <li>
            <Link className="my-auto hover:text-yellow-400" to="/news">
              Latest News
            </Link>
          </li>
          <li>
            <Link className="my-auto hover:text-yellow-400" to="/contact-us">
              Contact Us
            </Link>
          </li>
          <li>
            <Link className="my-auto hover:text-yellow-400" to="/joinus">
              Join us
            </Link>
          </li>
        </span>
      </ul>
      <div className="flex lg:hidden">
        <Smallscreen />
      </div>
    </div>
  );
};
