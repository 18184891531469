import { Nav } from "../components/nav";
import { Link } from "react-router-dom";
import { Footer } from "../components/footer";

const Hero = { img: "/hero-5.jpeg", img2: "/hero-6.jpeg" };

const JoinusView = () => {
  return (
    <section>
      <span className="fixed z-0 w-screen h-screen">
        <img
          className="w-full"
          src={Hero.img}
          alt="Dimension hero background"
        />
      </span>
      <Nav />
      <div className="relative z-10 bg-gradient-to-b from-black/[0.7] to-black/[0.2] h-screen py-24 flex flex-col md:items-start lg:items-end">
        <div className="flex flex-col items-start my-auto">
          <div className="max-w-xl px-4 lg:mx-12">
            <div className="flex flex-col items-start space-y-6 my-auto">
              <h1 className="text-5xl text-white text-start lg:text-center font-bold">
                Join Our Team
              </h1>
              <p className="text-white text-start">
                At Dimension11, our core values are creativity, authenticity, and entertainment for gamers in everything we do. In addition, we value diversity and equality at all levels of our organization to ensure every person has a voice when it comes to game design and development. Our teammates love the diverse views that contribute to the way our games are being created. As a team of creatives and developers, we encourage our staff and customers to escape from the limitations of the 3-dimensional world and launch into the limitless 11th Dimension.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-10 bg-white p-12">
        <div className="flex flex-col space-y-4 lg:flex-row lg:justify-between lg:space-x-12 text-black">
          <div className="flex flex-col items-start space-y-6 my-auto">
            <div className="flex flex-col space-y-2">
              <h1 className="font-bold text-4xl">
                Full-time and Contract Roles
              </h1>
              <p className="text-xl">
                Do you think you embody our core values? If you see a full-time or contractor role, please {" "}
                <a
                  className="text-yellow-700 hover:text-yellow-500"
                  href="mailto:contact@dimension11studios.com?subject=Applying For Full Time Role"
                >
                 email
                </a>
                {" "} your resume (cv) and a brief introduction. We’d love to hear from you!
              </p>
            </div>
            <div className="flex items-center flex-col space-y-12 lg:flex-row lg:justify-center lg:items-start w-max lg:space-y-0 lg:space-x-36">
              <div>
                <h4 className="font-bold text-2xl mb-4">Art</h4>
                <ul className="flex flex-col items-start space-y-2">
                  <p className="text-yellow-600 ">
                    Lead UI/UX Artist
                  </p>
                  <p className="text-yellow-600 ">
                    Lead VFX Artist
                  </p>
                  <p className="text-yellow-600 ">
                    Character Artist
                  </p>
                  <p className="text-yellow-600 ">
                    Senior Lighting Artist
                  </p>
                  <p className="text-yellow-600 ">
                    Senior Technical Artist
                  </p>
                </ul>
              </div>
              <div>
                <h4 className="font-bold text-2xl mb-4">Production</h4>
                <ul className="flex flex-col items-start space-y-2">
                  <p className="text-yellow-600 ">
                    Lead Cinematic Director
                  </p>
                </ul>
              </div>
              {/* <div>
                <h4 className="font-bold text-2xl mb-4">Engineering</h4>
                <ul className="flex flex-col items-start space-y-2">
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Online Network Engineer
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Quality Engineer Lead
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Rendering Engineer{" "}
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Senior Engine Systems Engineer (x2 Positions)
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Senior Gameplay Engineer
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Senior Online Engineer
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Senior UI Engineer
                  </Link>
                </ul>
              </div> */}

              {/* <div>
                <h4 className="font-bold text-2xl mb-4">Design</h4>
                <ul className="flex flex-col items-start space-y-2">
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Live Design Director
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Senior Multiplayer Designer
                  </Link>
                  <Link to="" className="text-yellow-600 hover:text-yellow-500">
                    Story Director
                  </Link>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="relative z-10 bg-white py-20 px-4 lg:px-0">
        <div className="flex flex-row justify-start container mx-auto text-black">
          <div className="flex flex-col items-start space-y-6 my-auto">
            <div>
              <h1 className="font-bold text-4xl">Contract Roles</h1>
            </div>
            <div className="max-w-xl">
              <p>
                Dimension11 does not directly hire temporary roles. But, we do
                use external developers to take on specific projects on behalf
                of Dimension11. These developers hire candidates directly to
                fill these temporary roles. Below are examples of the types of
                temporary roles that one of our external developers may need to
                fill during the lifetime of a project. To find out more about
                these and other open temporary roles please contact:
                Dimension11studios@gmail.com
              </p>
              <hr className="my-4" />
              <ul className="flex flex-col items-start space-y-2 font-light text-xl text-slate-500">
                <small>Software Engineer (Game Audio)</small>
                <small>Technical Artist</small>
                <small>Talent & Culture Specialist</small>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="bg-white pb-20 px-4 lg:px-0">
        <div className="flex flex-row justify-start container mx-auto text-black">
          <div className="flex flex-col items-start space-y-6 my-auto">
            <div>
              <h1 className="font-bold text-4xl">Relocation Resources</h1>
            </div>
            <div className="max-w-7xl">
              <p>
                Moving to a new place takes time and planning, especially during
                the current circumstances. A move is different for everyone but
                there are some common factors to consider. If you are relocating
                to BC, below is a list of resources to help
              </p>
              <hr className="my-6" />

              <ul className="flex flex-col items-start space-y-2">
                <Link to="" className="text-yellow-600 hover:text-yellow-500">
                  BC Newcomers Guide
                </Link>
                <Link to="" className="text-yellow-600 hover:text-yellow-500">
                  Finding a School Zone
                </Link>
                <Link to="" className="text-yellow-600 hover:text-yellow-500">
                  Driving & Insuring Your Vehicle
                </Link>
                <Link to="" className="text-yellow-600 hover:text-yellow-500">
                  Provincial COVID-19 Response
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <div className="relative z-10">
        <Footer />
      </div>
    </section>
  );
};

export default JoinusView;
