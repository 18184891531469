import "./index.css";
import { useNavigate } from "react-router-dom";

export const SimpleCards = ({ img, btn, url, cap }) => {
  const router = useNavigate();

  return (
    <div className="w-full h-full">
      <div className="relative">
        <img className="backdrop-blur rounded-sm" src={img} alt="" />
        {btn && cap == null ? (
          <div className="w-full flex flex-col">
            <button
              onClick={() => {
                router(url, { replace: true });
              }}
              className="my-auto py-2 px-6 bg-yellow-400 hover:bg-yellow-500"
            >
              {btn}
            </button>
          </div>
        ) : btn == null && cap ? (
          <div className="my-2">
            <h3 className="text-2xl text-black text-center font-medium">
              {cap}
            </h3>
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export const NewsCards = ({ row, img, date, link, heading, content, target }) => {
  return (
    <div className="w-full h-full">
      <div
        className={`flex ${
          row ? "flex-row justify-center space-x-12" : "flex-col items-start"
        }`}
      >
        <div>
          {img && <img className="rounded-sm w-full" src={img} alt="" />}
        </div>
        <div
          className={`flex flex-col items-start space-y-4 ${
            row ? false : "py-4"
          }`}
        >
          <h4 className="text-xl font-bold">{date ? date : false}</h4>
          <h1 className="text-3xl font-bold">{heading ? heading : false}</h1>
          <p className="text-xl font-normal">{content ? content : false}</p>
          <a href={link ? `${link}` : "/"} target={ target === "" ? "" : "_blank" }>
            <button className="my-auto py-1 px-8 border-4 bg-white border-yellow-400 hover:bg-black hover:text-white hover:border-black">
              Read more
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
